import Vue from "vue"
import VueRouter from "vue-router"
import Redirect from "@/views/Redirect.vue"
import store from "@/store/index"

Vue.use(VueRouter)

let routes = [
	{
		path: "*",
		component: () => import("../views/404.vue")
	},
	{
		path: "/",
		name: "Home",
		redirect: "/ai"
	},
	{
		path: "/ai",
		name: "Ai",
		layout: "dashboard",
		component: () => import("../views/Ai.vue"),
		meta: { title: "首页", keepAlive: true }
	},
	{
		path: "/write",
		name: "Write",
		layout: "dashboard",
		component: Redirect,
		redirect: "/",
		meta: { title: "创作" },
		children: [
			{
				path: "/",
				name: "WriteIndex",
				component: () => import("../views/Write.vue"),
				meta: { keepAlive: true }
			},
			{
				path: "create",
				name: "WriteCreate",
				component: () => import("../views/WriteCreate.vue"),
				meta: { title: "生成", auth: true }
			}
		]
	},
	{
		path: "/role",
		name: "Role",
		layout: "dashboard",
		component: Redirect,
		redirect: "/",
		meta: { title: "角色" },
		children: [
			{
				path: "/",
				name: "RoleIndex",
				component: () => import("../views/Role.vue"),
				meta: { keepAlive: true }
			},
			{
				path: "chat",
				name: "RoleChat",
				component: () => import("../views/RoleChat.vue"),
				meta: { title: "聊天", auth: true }
			}
		]
	},
	{
		path: "/drawing",
		name: "Drawing",
		component: Redirect,
		redirect: "/",
		meta: { title: "绘画" },
		children: [
			{
				path: "/",
				name: "DrawingIndex",
				layout: "dashboard",
				component: () => import("../views/Drawing.vue"),
				meta: { keepAlive: true }
			},
			{
				path: "canvas",
				name: "DrawingCanvas",
				component: () => import("../views/DrawingCanvas.vue"),
				meta: { title: "以文生图", auth: true }
			},
			{
				path: "picture",
				name: "DrawingPicture",
				component: () => import("../views/DrawingPicture.vue"),
				meta: { title: "以图生图", auth: true }
			},
			{
				path: "professional",
				name: "DrawingProfessional",
				component: () => import("../views/DrawingProfessional.vue"),
				meta: { title: "专业生图", auth: false }
			}
		]
	},
	{
		path: "/more",
		name: "More",
		layout: "dashboard",
		component: Redirect,
		redirect: "/",
		meta: { title: "更多应用" },
		children: [
			{
				path: "/",
				name: "MoreIndex",
				component: () => import("../views/More.vue"),
				meta: { keepAlive: true }
			},
			{
				path: "mindmap",
				name: "MindMap",
				component: () => import("../views/MindMap.vue"),
				meta: { title: "思维导图" }
			}
		]
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		layout: "dashboard",
		component: () => import("../views/Dashboard.vue"),
		meta: { title: "仪表盘", keepAlive: true }
	},
	{
		path: "/profile",
		name: "Profile",
		layout: "dashboard",
		component: () => import("../views/Profile.vue"),
		meta: { title: "用户信息", auth: true, layoutClass: "layout_profile" }
	},
	{
		path: "/recharge",
		name: "Recharge",
		layout: "dashboard",
		component: () => import("../views/Recharge.vue"),
		meta: { title: "充值中心", keepAlive: true, auth: true, layoutClass: "layout_profile" }
	},
	{
		path: "/photo",
		name: "Photo",
		layout: "dashboard",
		component: () => import("../views/DrawingCenter.vue"),
		meta: { title: "作品集", auth: true, layoutClass: "layout_profile" }
	},
	{
		path: "/createVideo",
		name: "Createvideo",
		component: () => import("../views/CreateVideo.vue"),
		meta: { title: "文生视频"}
	},
	{
		path: "/test",
		name: "test",
		component: () => import("../views/test.vue"),
		meta: { title: "测试"}
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
	route.meta = route.meta || {}
	route.meta.layout = route.layout || parentLayout

	if (route.children) {
		route.children = route.children.map(childRoute => addLayoutToRoute(childRoute, route.meta.layout))
	}
	return route
}

routes = routes.map(route => addLayoutToRoute(route))

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth"
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: "smooth"
		}
	}
})

router.beforeEach((to, from, next) => {
	if (to.meta.auth === true && !store.state.user.token) {
		store.commit("user/setLoginPopup", true)
	} else {
		next()
	}
})

router.afterEach((to, from) => {})

export default router
